/* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px #00a9fd inset;
  border-radius: 50%;
  position: relative;
  animation: l11 7s infinite;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: calc(100% + 3px);
  left: calc(50% - 12.5px);
  box-shadow: inherit;
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  transform-origin: 50% -28px;
  animation: l11 1.5s infinite;
}
.loader:after {
  animation-delay: -0.75s;
}
@keyframes l11 {
  100% {
    transform: rotate(360deg);
  }
}

.remove-scrolling {
  height: 100%;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.customScroll {
  overflow-y: scroll;
}

.customScroll::-webkit-scrollbar {
  width: 12px; /* Width of the entire scrollbar */
}

.customScroll::-webkit-scrollbar-track {
  background: #2e2e2e71; /* Color of the track */
  border-radius: 10px; /* Rounded corners of the track */
}

.customScroll::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners of the scrollbar thumb */
  border: 3px solid #2e2e2e71; /* Space around the thumb */
}

.customScroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}
